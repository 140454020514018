import React from 'react';
import './BackButton.css';
import PropTypes from "prop-types";

const BackButton = (props) => {

    return (
        <a href={props.backLink}>
            <i className="fa fa fa-arrow-left fa-2x w3-padding w3-opacity pointer"></i>
        </a>
    );
};

BackButton.propTypes = {
    backLink: PropTypes.string.isRequired
};
export default BackButton;
