import React from 'react';

const DrawingDescription = (props) => {

    const Paragraph = ({value}) => (
        <p>{value}</p>
    );

    const Description = ({description}) => (
        <div>
            {
                description.lines.map((line, i) => <Paragraph key={i} value={line}/>)
            }
        </div>
    );

    const descriptionAvailable = props.description && props.description.lines

    return (
        <>
            {!descriptionAvailable && (
                <p>I really should put some description here...</p>
            )}
            {descriptionAvailable && (
                <Description description={props.description}/>
            )}
        </>
    );
}

export default DrawingDescription;
