import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Archive from "./pages/Archive";
import DrawingDetail from "./components/DrawingDetail";
import ScrollToTop from "./components/scroll/ScrollToTop";


function App() {

    return (
        <BrowserRouter>
            <ScrollToTop>
                <div className="w3-content" style={{maxWidth: '1500px'}}>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={<Home/>}/>
                            <Route exact path="/archive" element={<Archive/>}/>
                            <Route path="/drawing/:drawingName" element={<DrawingDetail/>}/>
                            <Route path="contact" element={<Contact/>}/>
                            <Route path="about" element={<About/>}/>
                            <Route path="*" element={<NoPage/>}/>
                        </Route>
                    </Routes>
                </div>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;

