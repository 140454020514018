import React, {useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import drawings from "../data/drawings";
import './DrawingDetail.css'
import DrawingDescription from "./text/DrawingDescription";
import DrawingPhotoToggle from "./toggle/DrawingPhotoToggle";
import BackButton from "./BackButton";
import {ScreenContext} from "../pages/Layout";

const DrawingDetail = (props) => {

    const {screen} = useContext(ScreenContext);
    const [showDrawing, setShowDrawing] = useState(true);
    const [showCourse, setShowCourse] = useState(false);

    let params = useParams();
    const drawing = drawings.find(obj => {
        return obj.name === params.drawingName;
    })

    const onImageClick = () => {
        if (showDrawing) {
            onToggleOn();
        } else {
            onToggleOff();
        }
    }

    const onToggleOn = () => {
        setShowDrawing(false);
        // plausible('photo');
    }

    const onToggleOff = () => {
        setShowDrawing(true);
    }

    function handleToggleCountTriggered() {
        setShowCourse(true);
    }

    let drawingImageClass = "";
    let photoImageClass = "";
    if (showDrawing) {
        drawingImageClass = "drawing-image";
        photoImageClass = "photo-image transparant";
    } else {
        drawingImageClass = "drawing-image transparant";
        photoImageClass = "photo-image";
    }

    const backLink = "/#" + drawing.name;
    const showLink = drawing && drawing.link;

    // Always showing the 600px version
    const drawingSrc = screen.isMobile ? drawing.drawing600 : drawing.drawing600;
    const imageSrc = screen.isMobile ? drawing.photo600 : drawing.photo600;

    return (
        <>
            <div className="w3-container w3-margin-top w3-margin-bottom drawingDetail">
                <div className="w3-row">
                    <div className="w3-twothird">
                        <div className="">
                            <div className="image-container" onClick={onImageClick}>
                                <img src={drawingSrc} className={drawingImageClass} alt={drawing.promoText}/>
                                <img src={imageSrc} className={photoImageClass} alt={drawing.promoText}/>
                            </div>
                        </div>
                    </div>
                    <div className="w3-third">
                        {drawing.photo300 && (
                            <div className=" w3-padding">
                                <DrawingPhotoToggle toggleOn={showDrawing} onToggleOn={onToggleOn}
                                                    onToggleOff={onToggleOff}
                                                    onToggleCountTriggered={handleToggleCountTriggered}/>
                            </div>
                        )}
                        {/*{showCourse && (*/}
                        {/*    <div id=" course"*/}
                        {/*         className=" w3-panel w3-pale-yellow w3-leftbar w3-border w3-border-yellow">*/}
                        {/*        <p>Learn how to create these drawings in 5 easy steps.</p>*/}
                        {/*        <p>Anyone can do this!</p>*/}
                        {/*        <Link to="/*/}
                        {/*            ">Show me*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        <div className="w3-padding">
                            <DrawingDescription description={drawing.description}/>
                            {showLink && (
                                <a href={drawing.link.url} target="_blank" rel="noreferrer">{drawing.link.text}</a>
                            )}
                        </div>
                        <BackButton backLink={backLink}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DrawingDetail;
