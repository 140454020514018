import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import './DrawingSummary.css';
import PropTypes from "prop-types";
import LazyLoad from "react-lazy-load";
import {ScreenContext} from "../pages/Layout";

const DrawingSummary = (props) => {
    const [imgHeight, setImageHeight] = useState(300);
    const { screen } = useContext(ScreenContext);
    const drawing = props.src;
    const index = props.index;
    const lazyLoad = index > 1;
    const loadingMode = lazyLoad ? 'lazy' : 'eager';

    const onImgLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
        setImageHeight(offsetHeight)
    };

    //only using the 600px on the frontpage
    const imageSrc = screen.isMobile ? drawing.drawing600 : drawing.drawing600;

    return (
        <div className="w3-half w3-section">
            <div className="w3-center">
                <div id={drawing.name} className="w3-margin-bottom ">
                    <Link to={`/drawing/${drawing.name}`} className="no-underline">
                        <LazyLoad height={imgHeight} offset={300}>
                            <img onLoad={onImgLoad} src={imageSrc} className="imageSummary" alt={drawing.alt}/>
                        </LazyLoad>
                        <p>{drawing.promoText}</p>
                    </Link>
                    <p>
                        <Link to={`/drawing/${drawing.name}`}>
                            <button className="w3-button w3-white w3-border w3-round-large w3-margin-left">More
                                info...
                            </button>
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

DrawingSummary.propTypes = {
    index: PropTypes.number.isRequired,
    src: PropTypes.object.isRequired
};

export default DrawingSummary;


