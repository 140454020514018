import React from 'react';
import './footer.css';
import CupOfCoffee from '../../images/buy-me-a-coffe-white.png';

const Footer = () => {

    const scrollToTop = () => {
        console.log("==> back to top")
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    return (
        <>
            <footer className="w3-container w3-margin-top w3-padding-16 w3-light-grey w3-center w3-opacity">
                {/*<GumroadEmailSubscription/>*/}
                <div className="w3-panel">I can't recommend it, but if you insist, <br/>you can follow me on <a
                    href="https://www.instagram.com/madebyjadu"><i
                    className="fa fa-instagram w3-hover-opacity"/> Instagram</a>
                </div>
                <div id="back-to-top" className="w3-panel">
                    <a onClick={scrollToTop}>Back to top</a>
                </div>

                {/*<i className="fa fa-facebook-official w3-hover-opacity"></i>*/}
                {/*<i className="fa fa-snapchat w3-hover-opacity"></i>*/}
                {/*<i className="fa fa-pinterest-p w3-hover-opacity"></i>*/}
                {/*<i className="fa fa-twitter w3-hover-opacity"></i>*/}
                {/*<i className="fa fa-linkedin w3-hover-opacity"></i>*/}
            </footer>

        </>
    );
};

export default Footer;
