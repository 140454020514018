import React from 'react';
import './SpokenText.css';
import PropTypes from "prop-types";

const SpokenText = (props) => {

    return (
        <span className="w3-large"><i>"{props.text}"</i></span>
    );
};

SpokenText.propTypes = {
    text: PropTypes.string.isRequired
};
export default SpokenText;
