import React from 'react';
import DrawingSummary from '../components/DrawingSummary.js';
import drawings from "../data/drawings";
import './home.css';

const Home = () => {

    return (
        <>
            <div className="w3-row subTitle">
                <div className="w3-center w3-opacity"><p>All the stuff made by Jadu</p></div>
            </div>
            <div className="w3-container">
                <div className="w3-row-padding" id="drawingList">
                    {drawings.map((drawing, index) => (
                        <DrawingSummary key={drawing.name} index={index} src={drawing}/>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Home;

