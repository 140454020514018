import React from 'react';
import drawings from '../data/drawings';
import {Link} from 'react-router-dom';
import BackButton from "../components/BackButton";

const Archive = () => {
    return (
        <div className="w3-container">
            <h3>Archive of drawings</h3>
            {drawings.map((drawing, index) => (
                <ul className="w3-ul">
                    <li key={index}><Link to={`/drawing/${drawing.name}`}>{drawing.promoText}</Link></li>
                </ul>
            ))}
            <BackButton backLink={"/"}/>
        </div>
    );
};

export default Archive;