import React, {useEffect} from 'react';
import BackButton from "../components/BackButton";

const NoPage = () => {

    // useEffect(() => {
    //     plausible("404",{ props: { path: document.location.pathname } });
    // });

    return(
        <>
        <div className="w3-panel">$nbsp;</div>
        <div className="w3-panel w3-center w3-opacity">
            <h2>This page sadly does not exist...</h2>
            <BackButton backLink={"/"}/>
        </div>
        </>
    );
};

export default NoPage;

