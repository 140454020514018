import React from 'react';
import {Outlet} from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import {useMediaQuery} from "react-responsive";

export const ScreenContext = React.createContext(null);

const Layout = () => {

    const isDesktop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTablet = useMediaQuery({query: '(min-width: 401px, max-width: 1223px)'});
    const isMobile = useMediaQuery({query: '(max-width: 400px)'});
    const screen = {
        isMobile: isMobile,
    }

    return (
        <ScreenContext.Provider value={{screen}}>
            <Header/>
            <Outlet/>
            <Footer/>
        </ScreenContext.Provider>
    )
};

export default Layout;

