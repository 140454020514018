import maconDrawing300 from '../images/2022-macon-drawing-300px.png';
import maconDrawing600 from '../images/2022-macon-drawing-600px.png';
import maconPhoto300 from '../images/2022-macon-photo-300px.png';
import maconPhoto600 from '../images/2022-macon-photo-600px.png';
import transportErgonomiqueDrawing300 from '../images/2022-transport-ergonomique-drawing-300px.png';
import transportErgonomiqueDrawing600 from '../images/2022-transport-ergonomique-drawing-600px.png';
import transportErgonomiquePhoto300 from '../images/2022-transport-ergonomique-photo-300px.png';
import transportErgonomiquePhoto600 from '../images/2022-transport-ergonomique-photo-600px.png';
import oldWoodDrawing300 from '../images/2022-old-wood-drawing-300px.png';
import oldWoodDrawing600 from '../images/2022-old-wood-drawing-600px.png';
import oldWoodPhoto300 from '../images/2022-old-wood-photo-300px.png';
import oldWoodPhoto600 from '../images/2022-old-wood-photo-600px.png';
import rsCarDrawing300 from '../images/2022-rs-car-300px.png';
import rsCarDrawing600 from '../images/2022-rs-car-600px.png';
import rsCarPhoto300 from '../images/2022-rs-car-photo.png'; // TODO
import rsCarPhoto600 from '../images/2022-rs-car-photo.png'; // TODO
import sukhaTableDrawing300 from '../images/2022-sukha-table-300px.png';
import sukhaTableDrawing600 from '../images/2022-sukha-table-600px.png';
import sukhaTablePhoto300 from '../images/2022-sukha-table-photo-300px.png';
import sukhaTablePhoto600 from '../images/2022-sukha-table-photo-600px.png';
import rocketInPolandDrawing300 from '../images/2022-rocket-in-poland-300px.png';
import rocketInPolandDrawing600 from '../images/2022-rocket-in-poland-600px.png';
import rocketInPolandPhoto300 from '../images/2022-rocket-in-poland-photo-300px.png';
import rocketInPolandPhoto600 from '../images/2022-rocket-in-poland-photo-600px.png';
import ramones1978Drawing300 from '../images/1978-ramones-tv-interview-drawing-300px.png';
import ramones1978Drawing600 from '../images/1978-ramones-tv-interview-drawing-600px.png';
import ramones1978Photo300 from '../images/1978-ramones-tv-interview-photo-300px.png';
import ramones1978Photo600 from '../images/1978-ramones-tv-interview-photo-600px.png';
import underPerformingCityGardenDrawing300 from '../images/2023-under-performing-city-garden-drawing-300px.png';
import underPerformingCityGardenDrawing600 from '../images/2023-under-performing-city-garden-drawing-600px.png';
import underPerformingCityGardenPhoto300 from '../images/2023-under-performing-city-garden-photo-300px.png';
import underPerformingCityGardenPhoto600 from '../images/2023-under-performing-city-garden-photo-600px.png';
import thingsWillSoonImproveDrawing300 from '../images/2023-things-willl-soon-improve-drawing-300px.png';
import thingsWillSoonImproveDrawing600 from '../images/2023-things-willl-soon-improve-drawing-600px.png';
import thingsWillSoonImprovePhoto300 from '../images/2023-things-will-soon-improve-photo-300px.png';
import thingsWillSoonImprovePhoto600 from '../images/2023-things-will-soon-improve-photo-600px.png';
import freeRangeCutleryDrawing300 from '../images/2024-free-range-cutlery-drawing-300px.png';
import freeRangeCutleryDrawing600 from '../images/2024-free-range-cutlery-drawing-600px.png';
import freeRangeCutleryPhoto300 from '../images/2024-free-range-cutlery-photo-300px.png';
import freeRangeCutleryPhoto600 from '../images/2024-free-range-cutlery-photo-600px.png';
import mrPutinWentToChurchDrawing300 from '../images/2023-mr-putin-went-to-church-drawing-300px.png';
import mrPutinWentToChurchDrawing600 from '../images/2023-mr-putin-went-to-church-drawing-600px.png';
import mrPutinWentToChurchPhoto300 from '../images/2023-mr-putin-went-to-church-photo-300px.png';
import mrPutinWentToChurchPhoto600 from '../images/2023-mr-putin-went-to-church-photo-600px.png';
import allesLebtDrawing300 from '../images/2024-alles-lebt-drawing-300px.png';
import allesLebtDrawing600 from '../images/2024-alles-lebt-drawing-600px.png';
import allesLebtPhoto300 from '../images/2024-alles-lebt-photo-300px.png';
import allesLebtPhoto600 from '../images/2024-alles-lebt-photo-600px.png';
import soupeDeGuerreDrawing300 from '../images/2024-soupe-de-guerre-drawing-300px.png';
import soupeDeGuerreDrawing600 from '../images/2024-soupe-de-guerre-drawing-600px.png';
import soupeDeGuerrePhoto300 from '../images/2024-soupe-de-guerre-photo-300px.png';
import soupeDeGuerrePhoto600 from '../images/2024-soupe-de-guerre-photo-600px.png';


const drawings = [
     {
         name: `free-range-cutlery`,
         promoText: 'Free range cutlery',
         description: {
             lines: ['Spoons, knives and forks all lead very segregated lives.',
             'Like chicken they are always stacked together in small, confined spaces. A knife will only sees other knives, and a spoon will never lie next to a pretty fork.',
             'Now is the time to free your cutlery!',
             'So just throw all your spoons, knives and forks in one big drawer! This will make your cutlery much happier, and it saves you from the incredibly boring sorting job.',
             '#free-range-cutlery, #knives-need-space, #free-forks-forever, #stop-sorting-spoons',
             ]
         },
         drawing300: freeRangeCutleryDrawing300,
         drawing600: freeRangeCutleryDrawing600,
         photo300: freeRangeCutleryPhoto300,
         photo600: freeRangeCutleryPhoto600,
     },
        {
            name: `soupe-de-guerre`,
            promoText: 'Soupe de guerre...',
            description: {
                lines: ['In the middle of a short but fierce battle we were served a "soup de guerre".',
                'The soup was delicious, but we lost the battle...',
                  'In memoriam, Christa.'
                ]
            },
            drawing300: soupeDeGuerreDrawing300,
            drawing600: soupeDeGuerreDrawing600,
            photo300: soupeDeGuerrePhoto300,
            photo600: soupeDeGuerrePhoto600,
        },
    {
        name: `alles-lebt`,
        promoText: 'Alles lebt...',
        description: {
            lines: ['This poster of the Museum der Kulturen hangs opposite Augustinergasse 17 in Basel...',
            'In memoriam, Christa.'
            ]
        },
        drawing300: allesLebtDrawing300,
        drawing600: allesLebtDrawing600,
        photo300: allesLebtPhoto300,
        photo600: allesLebtPhoto600,
    },
    {
        name: `mr-putin-went-to-church`,
        promoText: 'Mr Putin went to church...',
        description: {
            lines: ['Mr Putin is a religious man.',
            'Every year with Christmas he visits the Orthodox Cathedral of the Annunciation in Moscow.',
            'This makes it difficult to understand why he would order the bombing of another Orthodox Church.',
            'But on July 23 2023, the Orthodox Transfiguration Cathedral in Odessa was severely damaged by Russian missiles.',
            'I made this drawing of it.',
            ]
        },
        // link: {
        //     url: 'https://en.wikipedia.org/wiki/Transfiguration_Cathedral_in_Odesa',
        //     text: 'Transfiguration Cathedral in Odessa'
        // },
        drawing300: mrPutinWentToChurchDrawing300,
        drawing600: mrPutinWentToChurchDrawing600,
        photo300: mrPutinWentToChurchPhoto300,
        photo600: mrPutinWentToChurchPhoto600,
    },
    {
        name: `things-will-soon-improve`,
        promoText: 'Things will soon improve...',
        description: {
            lines: ['When your child is in hospital, there is a lot of technology around.',
                'You would expect the technology to somehow comfort you.',
                'That all is under control.',
                'But I think technology fails in this regard.',
                'It does not take away your worries, no matter how many machines they connect.',
            ]
        },
        drawing300: thingsWillSoonImproveDrawing300,
        drawing600: thingsWillSoonImproveDrawing600,
        photo300: thingsWillSoonImprovePhoto300,
        photo600: thingsWillSoonImprovePhoto600,
    },
    {
        name: `under-performing-city-garden`,
        promoText: 'An under performing city garden...',
        description: {
            lines: ['This morning I told the garden: "You are under performing."',
                'The garden said: "What do you mean?"',
                '"Well, I said, I do not think you are currently doing a great job as a garden."',
                '"There is not a lot of color in the garden. Half of the plants seem dead. And sitting on that bench is currently very unpleasant. It is cold and wet."',
                '"But it is winter", the garden said.',
                '"That is no excuse", I said, "I have to work in winter too"',
                '"Compare yourself with a summer garden. They perform much better. They are warm and colorful. The plants are all alive. It is a joy to be in a summer garden all day".',
                '"So my conclusion is clear", I said, "You are an under performing garden".',
                'The garden did not respond. This is, of course, the typical behavior of a winter garden.',
                'My advice: avoid them if you can!'
            ]
        },
        drawing300: underPerformingCityGardenDrawing300,
        drawing600: underPerformingCityGardenDrawing600,
        photo300: underPerformingCityGardenPhoto300,
        photo600: underPerformingCityGardenPhoto600,
    },
    {
        name: `ramones-1978-tv-interview`,
        promoText: 'Ramones 1978 TV interview...',
        description: {
            lines: ['Yesterday I watched a one hour TV interview with The Ramones.',
                'It is some time since I saw smoking, drinking, technical chaos, inaudible jokes, people leaving for the bathroom and beers being served from a suitcase on live TV...',
                'The number you see in the drawing is the phonenumber you can call to ask live questions.',
                'Click below to see The Ramones appearing on Efrom Allen\'s Underground TV show. This is the entire one hour episode, as originally aired on Manhattan cable in 1978. Copyright Worldwide Television Enterprises, all rights reserved.']
        },
        link: {
            url: 'https://www.youtube.com/watch?v=Pjd6-Heyjck',
            text: 'Ramones 1978 TV interview on Youtube'
        },
        drawing300: ramones1978Drawing300,
        drawing600: ramones1978Drawing600,
        photo300: ramones1978Photo300,
        photo600: ramones1978Photo600,
    },
    {
        name: `rocket-in-poland`,
        promoText: 'A rocket hit Poland...',
        description: {
            lines: ['A rocket hit a farm near the Polish village of Przewodow.',
                'Two people were reported killed.', 'Shocked to see how innocent people, again, get involved in a war they did not want or start.']
        },
        drawing300: rocketInPolandDrawing300,
        drawing600: rocketInPolandDrawing600,
        photo300: rocketInPolandPhoto300,
        photo600: rocketInPolandPhoto600,
    },
    {
        name: `sukha-amsterdam`,
        promoText: 'Sukha, the most beautiful store in Amsterdam...',
        description: {
            lines: ['Sukha sells sustainable clothing (and did so before the word sustainable was invented).',
                'But they also sell beautiful ceramics, which I tried to draw in this pen drawing',
                'Visit them at Haarlemmerstraat 110 in Amsterdam (close to the central station)']
        },
        link: {
            url: 'https://www.sukha.nl/',
            text: 'Sukha Amsterdam'
        },
        drawing300: sukhaTableDrawing300,
        drawing600: sukhaTableDrawing600,
        photo300: sukhaTablePhoto300,
        photo600: sukhaTablePhoto600,
    },
    {
        name: `macon-rue-paul-gateaud`,
        promoText: 'Macon, Rue Paul Gateaud. View from our hotel...',
        description: {
            lines: ['In September 2022 we visited Macon (France), and this was the view from our hotel room.',
                'I immediately was charmed by all the antennas placed on the roofs, something we do not see anymore in The Netherlands. I took a quick picture, and made this drawing at home.']
        },
        drawing300: maconDrawing300,
        drawing600: maconDrawing600,
        photo300: maconPhoto300,
        photo600: maconPhoto600,
    },
    {
        name: `rs-car-the-best-garage-in-france`,
        promoText: 'RS Car, the best garage in France, let me tell you why...',
        description: {
            lines:
                ['RS Car is the best garage in France.',
                    'We arrived after closing time, with a dead battery.',
                    'They were friendly, helped us right away, and within half an hour we were on the road again. Next day we drove back 1400 km to the Netherlands, without any problems!',
                    'So, visit them at: RS Car, 13 All. des Imprimeurs, 06700 Saint-Laurent-du-Var (near Nice), France.'],
        },
        drawing300: rsCarDrawing300,
        drawing600: rsCarDrawing600,
        photo300: rsCarPhoto300,
        photo600: rsCarPhoto600,
    },
    {
        name: `a-lot-of-wood`,
        promoText: 'Lot\'s of wood in this old French barn...',
        description: {
            lines:
                ['In the summer of 2022 I stumbled upon this old French barn. Not only did it store lot\'s of wood, the whole barn was made of an amazing construction of beams.',
                    'I thought all the lines of those beams together would give a great effect in a drawing.'
                ]
        },
        drawing300: oldWoodDrawing300,
        drawing600: oldWoodDrawing600,
        photo300: oldWoodPhoto300,
        photo600: oldWoodPhoto600,
    },
    {
        name: `transport-ergonomique`,
        promoText: 'Transport Ergonomique...',
        description: {
            lines: ['A friend was moving to the south of France. So everything had to fit in the car and this little trailer.',
                'And it did!'
            ]
        },
        drawing300: transportErgonomiqueDrawing300,
        drawing600: transportErgonomiqueDrawing600,
        photo300: transportErgonomiquePhoto300,
        photo600: transportErgonomiquePhoto600,
    }
];

export default drawings;
